<template>
  <div class="animated fadeIn">
    <loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="this.$config.LOADER.color"
      :opacity="this.$config.LOADER.opacity"
      :background-color="this.$config.LOADER.backgroundColor"
      :z-index="10000"
    ></loading>
    <div v-if="data.id > 0">

      <b-row class="mb-0">
        <b-col md="6">
          <div class="clearfix">
            <b-link
              class="pull-right mb-3"
              variant="primary"
              :to="{
                name: 'admin.customer-loan-accounts.deposit-woman-new',
                params: { id: this.loanId },
              }"
            >
              Засах
            </b-link>
          </div>
          <b-card class="mb-0">
            <h4 class="card-title text-uppercase text-primary">
              Барьцаа хөрөнгө
            </h4>
            <b-list-group class="mt-4">
              <b-list-group-item
                class="pl-0 rounded-0 border-right-0 border-left-0"
              >
                <b-row>
                  <b-col sm="4"
                    ><span class="text-muted">Үйл ажиллагааны чиглэл</span>
                    <h5 class="mb-0">{{ data.depositSpec }}</h5></b-col
                  >
                  <b-col sm="4"
                    ><span class="text-muted">Үйл ажиллагаа явуулж эхэлсэн он</span>
                    <h5 class="mb-0">{{ data.depositOperatedSince }}</h5></b-col
                  >
                  <b-col sm="4"
                    ><span class="text-muted">Үйл ажиллагаа</span>
                    <h5 class="mb-0">{{ data.depositOperation }}</h5></b-col
                  >
                </b-row>
              </b-list-group-item>
              <b-list-group-item
                class="pl-0 rounded-0 border-right-0 border-left-0"
              >
                <b-row>
                  <b-col sm="6"
                    ><span class="text-muted">Үйл ажиллагааны байршил</span>
                    <h5 class="mb-0">{{ data.depositOperatingLocation }}</h5></b-col
                  >
                  <b-col sm="6"
                    ><span class="text-muted">Үйл ажиллагааны байршилын эзэмшил</span>
                    <h5 class="mb-0">{{ data.depositOperatingLocationType }}</h5></b-col
                  >
                </b-row>
              </b-list-group-item>
              <b-list-group-item
                class="pl-0 rounded-0 border-right-0 border-left-0"
              >
                <b-row>
                  <b-col sm="6"
                    ><span class="text-muted">Банкны нэр</span>
                    <h5 class="mb-0">{{ data.depositSavingBank }}</h5></b-col
                  >
                  <b-col sm="6"
                    ><span class="text-muted">Дансны дугаар</span>
                    <h5 class="mb-0">{{ data.depositSavingBankAccount }}</h5></b-col
                  >
                </b-row>
              </b-list-group-item>
              <b-list-group-item
                class="pl-0 rounded-0 border-right-0 border-left-0"
              >
                <b-row>
                  <b-col sm="6"
                    ><span class="text-muted">Өрх толгойлсон эсэх</span>
                    <h5 class="mb-0">{{ data.isSingleMother }}</h5></b-col
                  >
                  <b-col sm="6"
                    ><span class="text-muted">Хүүхдийн тоо</span>
                    <h5 class="mb-0">{{ data.childrenCount }}</h5></b-col
                  >
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <b-button
        variant="primary"
        :to="{
          name: 'admin.customer-loan-accounts.deposit-woman-new',
          params: { id: this.loanId },
        }"
      >
        Барьцаа хөрөнгө бүртгэх
      </b-button>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "new-loan",
  props: ["loanId"],
  data: function() {
    return {
      isLoading: false,
      form: {
        depositSpec: "",
        depositOperatedSince: 0,
        depositOperation: "",
        depositOperatingLocation: "",
        depositOperatingLocationType: "",
        depositSavingBank: "",
        depositSavingBankAccount: "",
        isSingleMother: 0,
        childrenCount: ""
      },
      data: {
        id: 0,
      },
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData: function() {
      this.isLoading = true;
      this.$http
        .get(
          this.$config.API_URL + "LoanWebService/get_deposit_woman_data",
          {
            params: { id: this.$route.params.id },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            console.log(response)
            this.isLoading = false;
            // if(response.body.re)
            this.$data.data = response.body;
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
  },
};
</script>
