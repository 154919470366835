<template>
    <div class="animated fadeIn">
      <loading
        :active.sync="isLoading"
        :is-full-page="false"
        :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity"
        :background-color="this.$config.LOADER.backgroundColor"
        :z-index="10000"
      ></loading>
        <b-row class="mb-0">
          <b-col md="6">
            <b-card class="mb-0">
              <h4 class="card-title text-uppercase text-primary">
                Сургуулийн мэдээлэл
              </h4>
              <b-list-group class="mt-4">
                <b-list-group-item
                  class="pl-0 rounded-0 border-right-0 border-left-0"
                >
                  <b-row>
                    <b-col sm="6"
                      ><span class="text-muted">Сургууль</span>
                      <h5 class="mb-0">{{ school[data.school] }}</h5></b-col
                    >
                    <b-col sm="6"
                      ><span class="text-muted">Курс</span>
                      <h5 class="mb-0">{{ data.level }}</h5></b-col
                    >
                  </b-row>
                </b-list-group-item>
                <b-list-group-item
                  class="pl-0 rounded-0 border-right-0 border-left-0"
                >
                  <b-row>
                    <b-col sm="6"
                      ><span class="text-muted">Мэргэжил</span>
                      <h5 class="mb-0">{{ studentProf[data.prof] }}</h5></b-col
                    >
                    <b-col sm="6"
                      ><span class="text-muted">Голч</span>
                      <h5 class="mb-0">{{ data.mediumScore }}</h5></b-col
                    >
                  </b-row>
                </b-list-group-item>

              </b-list-group>
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card class="mb-0">
              <h4 class="card-title text-uppercase text-primary">Хувийн мэдээлэл</h4>
              <b-list-group class="mt-4">
                <b-list-group-item
                  class="pl-0 rounded-0 border-right-0 border-left-0"
                >
                  <b-row>
                    <b-col sm="12"
                      ><span class="text-muted">Оршин суугаа хаяг</span>
                      <h5 class="mb-0">{{ data.address }}</h5></b-col
                    >
                    
                  </b-row>
                </b-list-group-item>
                <b-list-group-item
                  class="pl-0 rounded-0 border-right-0 border-left-0"
                >
                  <b-row>
                    <b-col sm="6"
                      ><span class="text-muted">Хаягийн Эзэмшлийн Байдал</span>
                      <h5 class="mb-0">{{ addressType[data.addressType] }}</h5></b-col
                    >
                    <b-col sm="6"
                      ><span class="text-muted">Тухайн Хаягт Оршин Суугаа Хугацаа</span>
                      <h5 class="mb-0">{{ addressTerm[data.addressTerm] }}</h5></b-col
                    >
                  </b-row>
                </b-list-group-item>
                <b-list-group-item
                  class="pl-0 rounded-0 border-right-0 border-left-0"
                >
                  <b-row>
                    <b-col sm="4"
                      ><span class="text-muted">Орон Нутгийн Оюутан Эсэх</span>
                      <h5 class="mb-0">
                        {{ isUb[data.isUb] }}
                      </h5></b-col
                    >
                    <b-col sm="4"
                      ><span class="text-muted">Хүүхэдтэй Эсэх </span>
                      <h5 class="mb-0">{{ isChild[data.isChild] }}</h5></b-col
                    >
                    <b-col sm="4"
                      ><span class="text-muted">Ажилладаг эсэх</span>
                      <h5 class="mb-0">{{ isWork[data.work] }}</h5></b-col
                    >
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </div>
  </template>
  
  <script>
  import moment from "moment";
  
  export default {
    name: "new-loan",
    props: ["loanId"],
    data: function() {
      return {
        isLoading: false,
        form: {
        },
        data: {
          id: 0,
        },
        school: { 
            "muis": "Монгол улсын их сургууль" ,
            "shutis": "Шинжлэх ухаан технологийн их сургууль",
            "ashius": "Анагаахын шинжлэх ухааны их сургууль",
            "haais" : "Хөдөө аж ахуйн их сургууль",
            "sezis" : "Санхүү эдийн засгийн их сургууь" ,
            "mubis" : "Монгол улсын боловсролын их сургууль",
            "suis"  : "Соёл урлагийн их сургууль",
            "huis"  : "Хүмүүнлэгийн ухааны их сургууль",
        },

        studentProf: {
            "1": "Хууль эрх зүй",
            "2": "Эдийн засаг Бизнес",
            "3": "Анагаах",
            "4": "Багш" ,
            "5": "Инженер",
            "6": "Хөдөө аж ахуй",
            "7": "Соёл урлаг",
            "8": "Хэвлэл мэдээлэл",
        },

        isWork: {
            "1": "Тийм",
            "2": "Үгүй",
            "3": "Заримдаа"
        },

        addressType: {
            "1": "Өөрийн",
            "2": "Эцэг эхийн",
            "3": "Түрээсийн",
            "4": "Хамаатан садангийн",
            "5": "Дотуур байр",
            "6": "Бусад"
        },

        addressTerm: {
            "1": "1 жил",
            "2": "2 жил",
            "3": "3 жил",
            "4": "4 жил",
            "5": "5 жил",
            "6": "6 жил",
            "7": "6с дээш жил"
        },

        isUb: {
            true: "Тийм",
            false: "Үгүй"
        },

        isChild: {
            true: "Тийм",
            false: "Үгүй"
        }
       
      };
    },
    created() {
      this.initData();
    },
    methods: {
      initData: function() {
        this.isLoading = true;
        this.$http
          .get(
            this.$config.API_URL + "LoanWebService/get_student_deposit_data",
            {
              params: { id: this.$route.params.id },
              headers: this.$store.getters.httpHeader,
              emulateJSON: true,
            }
          )
          .then(
            (response) => {
              //console.log(response)
              this.isLoading = false;
              // if(response.body.re)
              this.$data.data = response.body;
            },
            (response) => {
              this.isLoading = false;
            }
          );
      },
    },
  };
  </script>
  